import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import styled, { css } from 'styled-components'

import { Layout, Block, PopupChalet } from '../components'

import { container, padding, bgImage, bgIcon } from '../styles/global'
import { absoluteCover, media } from '../styles/utils'

const Venue = ({ pageContext, ...props }) => {
  const acf = JSON.parse(pageContext.acf_json)
  const renderSlider = images => {
    if (!acf.image_slider) return

    return (
      <Block
        layout={'slider'}
        images={acf.image_slider}
        sliderStyles={sliderStyles}
        fullScreen
        hoverArrows
      />
    )
  }

  const renderContact = () => {
    return (
      <TextWrapper>
        <Text>
          275-285 EXHIBITION ST, MELBOURNE 3000
          <br />
          0396637994
          <br />
          TUESDAY TO SUNDAY, 12PM TIL LATE
        </Text>
      </TextWrapper>
    )
  }


  return (
    <Layout menu={pageContext.menu} meta={acf.seo} overSlider>
      <Container>
        <Overlay />
        <Stack>
          <Logo slug={pageContext.slug} tagline={acf.heading} />
          {renderContact()}
          <Button href="/pepes/reserve">BOOK NOW</Button>
        </Stack>
        {renderSlider()}
      </Container>
      <PopupChalet />
    </Layout>
  )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``

// Layout

const Button = styled.a`
	background-color: #f8e1da;
  color: black;
	border-radius: 4px;
  padding: 14px 0 15px;
  
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  max-width: 250px;

  text-align: center;
	text-decoration: none;

  margin: 0 auto;
  margin-top: 20px;
  

  ${media.phone`
			margin-top: 0px;
			margin-bottom: 30px;
	`}
`

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -57%);
  z-index: 2;
`

const Container = styled.div`
  height: 100vh;
  position: relative;

  /* Slider Overrides */

  .slider-arrow {
    background-image: url(${require('../assets/icons/slider-arrow-white.svg')});
    color: white !important;
  }

  .hover-section {
    width: 180px;
  }
`

// const Popup = styled.div`
// 	position: fixed;
// 	top: 0px;
// 	left: 0px;
// 	width: 100vw;
// 	height: 100vh;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// `

const sliderStyles = css`
  width: 100vw;
  height: calc(100vh - 50px);
  background: black;

  .slider-image {
    opacity: 0.85;
  }

  ${media.tablet`
		height: 100vh;
	`}
`

// Logo

const Logo = styled.div`
  ${bgIcon}

  width: calc(100vw - 84px);
  margin-bottom: 30px;

  ${props => {
    if (props.slug == 'pepes')
      return css`
        background-image: url(${require('../assets/images/pepes-logo.svg')});
        height: 422px;
        width: 524px;

        ${media.phone`
				max-width: calc(100vw - 84px);
				&::before {
					content: '${props => props.tagline}';
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					text-align: center;

					font-weight: bold;
					color: white;
					margin-top: auto;
					text-transform: uppercase;
					font-size: 16px;
				}
			`}
      `

    if (props.slug == 'trunk-diner')
      return css`
        background-image: url(${require('../assets/images/trunk-diner-logo.svg')});
        max-width: 480px;
        max-height: 242px;
      `
  }}
`

// ButtonMenu
const ButtonMenu = styled.div`
  background-color: #451f20d6;
  position: absolute;
  z-index: 2;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, 50%);
  text-align: center;
  padding: 15px 25px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  &:hover {
    background-color: #845b5cd6;
  }
`

const Overlay = styled.div`
  background: radial-gradient(#524d4d75, #524d4d57);
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 2;
`

const TextWrapper = styled.div`
  padding-top: 20px;

  margin-bottom: 20px;

  ${media.phone`
		margin-bottom: 40px;
	`}
`

const Text = styled.div`
  color: white;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;

  p {
    color: white;
  }
`

//TopBanner
const TopBanner = styled.div`
  position: absolute;
  top: 0%;
  z-index: 2;
  width: 100vw;
  background-color: #451f20d6;
  text-align: center;

  ${media.tablet`
		top: 7%;
	`}
`
const TextBanner = styled.div`
  color: white;
  padding: 10px 10px;
  font-weight: bold;
  text-transform: uppercase;

  a {
    color: #ffbdbf;
    &:hover {
      color: #845b5cd6;
    }
  }
`
export default Venue
